import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  withCredentials: true
});

function apiCaller(path, options = {}) {
  const { data = {}, method = 'get' } = options;

  if (method === 'post') {
    data.csrf = Cookies.get('sbg-cs');
  }

  return new Promise(resolve => {
    api({
      url: `/${path}`,
      method,
      data
    })
      .then(async ({ data }) => {
        resolve(data);
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  });
}

export default apiCaller;

export { api };
