import Vue from 'vue';
import Vuex from 'vuex';
import popup from './popup';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pictures: [],
    picIndex: null,
    cartData: [],
    config: {}
  },
  modules: {
    popup
  },
  mutations: {
    openPic(state, pic) {
      state.pictures = [pic];
      state.picIndex = 0;
    },
    closePic(state) {
      state.picIndex = null;
    },
    addCartData(state, obj) {
      let data = [...state.cartData, obj];

      if (obj === null) {
        data = [];
      }

      state.cartData = data;
      localStorage.setItem('cartData', JSON.stringify(data));
    },
    setCartData(state, array) {
      state.cartData = array;
      localStorage.setItem('cartData', JSON.stringify(array));
    },
    setConfig(state, data) {
      state.config = data;
    }
  }
});
