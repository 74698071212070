<template>
  <div id="app">
    <Popup v-if="popup">
      <component :is="popup" />
    </Popup>
    <CoolLightBox :items="pictures" :index="picIndex" @close="close" />
    <router-view />
  </div>
</template>

<script>
import './style/_index.scss';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import CoolLightBox from 'vue-cool-lightbox';
import Popup from '@/components/common/popup/component/Popup';

export default {
  name: 'App',
  components: {
    CoolLightBox,
    Popup,
    Feedback: () => import('@/components/popup/feedback/Feedback'),
    CheckboxDetailKPT: () =>
      import('@/components/popup/checkboxDetail/CheckboxDetailKPT'),
    CheckboxDetailARS: () =>
      import('@/components/popup/checkboxDetail/CheckboxDetailARS'),
    CheckboxDetailSKS: () =>
      import('@/components/popup/checkboxDetail/CheckboxDetailSKS'),
    CheckboxDetailSOPP: () =>
      import('@/components/popup/checkboxDetail/CheckboxDetailSOPP'),
    CheckboxDetailXZP: () =>
      import('@/components/popup/checkboxDetail/CheckboxDetailXZP'),
    AddToCartWarning: () =>
      import('@/components/popup/addToCartWarning/AddToCartWarning')
  },
  mounted() {
    if (
      localStorage.getItem('cartData') &&
      localStorage.getItem('cartData').length
    ) {
      const data = JSON.parse(localStorage.getItem('cartData'));
      this.$store.commit('setCartData', data);
    }
  },
  computed: {
    popup() {
      return this.$store.state.popup.open;
    },
    pictures() {
      return this.$store.state.pictures;
    },
    picIndex() {
      return this.$store.state.picIndex;
    }
  },
  methods: {
    close() {
      this.$store.commit('closePic');
    }
  },
};
</script>
