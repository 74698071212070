import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSlickCarousel from 'vue-slick-carousel';
import config from '@/api/config'

Vue.config.productionTip = false;
Vue.component('Slick', VueSlickCarousel);

(async () => {
  const data = await config();
  store.commit('setConfig', data);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
})()
