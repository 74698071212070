export default [
  {
    path: '',
    component: () => import('@/applications/documents/App.vue'),
    children: [
      {
        path: '/privacy-policy',
        name: 'DocumentsPrivacyPolicy',
        component: () => import('@/applications/documents/pages/PrivacyPolicy.vue')
      },
      {
        path: '/public-offer',
        name: 'DocumentsPublicOffer',
        component: () => import('@/applications/documents/pages/PublicOffer.vue')
      },
      {
        path: '/personal-data',
        name: 'DocumentsPersonalData',
        component: () => import('@/applications/documents/pages/PerosnalData.vue')
      }
    ]
  },
]
