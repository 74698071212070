<template>
  <div class="popup">
    <div class="popup__box" @click="close()">
      <div
        class="popup__content"
        :style="{ maxWidth: `${width}px` }"
        @click.stop=""
      >
        <div class="-wrapper">
          <span class="popup__content-close" @click="close()"></span>
          <div v-if="!loaded">
            <div class="loader">
              <div class="loader__text">
                Загружем форму...
              </div>
            </div>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  data: () => ({
    isPopup: true
  }),
  computed: {
    width: {
      get() {
        return this.$store.state.popup.width;
      }
    },
    loaded: {
      get() {
        return this.$store.state.popup.loaded;
      }
    }
  },
  methods: {
    close() {
      this.$store.commit('popup/close');
    }
  }
};
</script>

<style lang="scss">
@import '../style/popup';
</style>
