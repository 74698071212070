import Vue from 'vue';

export default {
  state: {
    open: '',
    full: false,
    width: 480,
    title: '',
    customOptions: {},
    loaded: true,
    back: ''
  },
  mutations: {
    open(state, options) {
      if (state.open && options.name === state.open) return;

      state.loaded = false;

      const set = (state, values) => {
        Object.keys(values).forEach(key => {
          const value = values[key];
          const type = typeof value;

          if (type === 'object' && value !== null && !Array.isArray(value)) {
            Vue.set(state, key, {});
            return set(state[key], values[key]);
          }

          if (typeof state[key] === 'undefined')
            return Vue.set(state, key, values[key]);

          state[key] = values[key];
        });
      };

      state.width = options.width ? options.width : 400;
      state.title = options.title ? options.title : '';
      state.back = options.back || '';
      options.customOptions = options.customOptions || {};

      Object.keys(state.customOptions).forEach(key => {
        delete state.customOptions[key];
      });

      set(state.customOptions, options.customOptions);

      state.open = options.name;

      if (options.full) state.full = true;

      document.body.style.overflow = 'hidden';
    },
    close(state) {
      state.full = false;
      state.open = '';
      document.body.style.overflow = 'auto';
    },
    loaded(state) {
      state.loaded = true;
    }
  },
  namespaced: true
};
