import Vue from 'vue';
import VueRouter from 'vue-router';

import documentsRouter from '@/applications/documents/router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/layouts/main/Main.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/layouts/login/Login.vue')
  },
  {
    path: '/local-cabinet',
    name: 'LocalCabinet',
    component: () => import('@/layouts/localCabinet/LocalCabinet.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/layouts/cart/Cart.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/layouts/payment/Payment.vue')
  },
  {
    path: '/finish_payment/:id',
    name: 'FinishPayment',
    component: () => import('@/layouts/payment/Finish.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/layouts/result/Result.vue')
  },
  ...documentsRouter,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

export default router;
